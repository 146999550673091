///include /assets/js/app/p/brand.js

const ShopNav = Vue.extend({
    template: "#shop-nav",
    data() {
        return {
            brand: null,
            catalogue: UserCatalogue.inst,
            customProductCategories: [],
            domainSearchText: "",
            settings: SharedSettings.inst,
            /**
             * @type {?GET.Stack.Catalogue.$ns.catalogue._design.product._view.byType.output}
             */
            productsAvailableByType: null,
            get navProducts() {
                return this.productsAvailableByType || {
                    package: {}
                }
            },
            get showPlansAsHosting() {
                if( this.navProducts.package ){
                    return !this.navProducts.package.linux && !this.navProducts.package.builder && !this.navProducts.package.windows && !this.navProducts.package.wordpress;
                } else {
                    return true;
                }
            },
            productBoxes: {
                package: {
                    link: {
                        // See mounted hook that may change this link
                        href: "/order-hosting",
                        label: translation.get("shop_view_plans"),
                    },
                    title: translation.get("shop_webhosting"),
                    icon: "far fa-cloud"
                },
                vps: {
                    link: {
                        href: "/order-vps",
                        label: translation.get("shop_view_plans"),
                    },
                    title: translation.get("shop_virtual_private_servers"),
                    icon: "far fa-server"
                },
                domain: {
                    link: {
                        href: "/domain-search",
                        label: translation.get("shop_domain_search"),
                    },
                    title: translation.get("shop_domain_names"),
                    icon: "far fa-map-marker"
                },
                cloud_server: {
                    link: {
                        href: "/build-server",
                        label: translation.get("group_cloud_server_add_title"),
                    },
                    title: translation.get("shop_cloud_servers"),
                    icon: "far fa-server"
                },
                custom: {
                    link: {
                        href: "/order-additional-services",
                        label: translation.get("shop_view"),
                    },
                    title: translation.get("shop_additional_products_services"),
                    icon: "far fa-plus-square"
                },
                stack_user_package_allowance: {
                    link: {
                        href: "/order-allowance",
                        label: translation.get("shop_order_allowance"),
                    },
                    title: translation.get("shop_order_allowance_title"),
                    icon: "far fa-cloud"
                },
                tls_certificate: {
                    link: {
                        href: "/order-ssl",
                        label: translation.get("shop_view_certificates"),
                    },
                    title: translation.get("shop_ssl_certificates"),
                    icon: "far fa-lock"
                },
                wordpress: {
                    link: {
                        href: "/order-wordpress-hosting",
                        label: translation.get("shop_order_allowance"),
                    },
                    title: translation.get("shop_order_wordpress_title"),
                    icon: 'fab fa-wordpress'
                }
            },
        }
    },
    computed: {
        webmailUrl() {
            return this.brand && new Brand(this.brand).webmailUrl
        },
        hasDomainProducts() {
            if(typeof SBDomainProduct != "undefined"){
                return this.catalogue.products && this.catalogue.products.some(p => p instanceof SBDomainProduct);
            } else {
                return null;
            }
        },
        hasTransferProducts() {
            if( typeof SBDomainProduct != "undefined" ){
                return this.catalogue.products && this.catalogue.products.some(p => p instanceof SBDomainProduct && p.offerTransfer );
            } else {
                return null;
            }
        },
        hasAllowanceProducts() {
            if( typeof SBDomainProduct != "undefined" ){
                return this.catalogue.products && this.catalogue.products.some(p => p instanceof SBStackUserPackageAllowanceProduct);
            } else {
                return null;
            }
        },
    },
    methods: {
        searchForDomain() {
            location.href =
                "/domain-search?" +
                $.param({domain: this.domainSearchText})
        },
        iconFor(cat) {
            if (!cat.icon) {
                return `<i class="far fa-shopping-cart fa-fw mr-2"></i>`
            }

            return `<i class="${cat.icon} fa-fw mr-2"></i>`
        },
        isVisible(type) {
            if (type === "custom") {
                if (
                    !("showDefaultAdditionalProductsCategory" in this.settings.config?.prefs)
                ) {
                    return true
                } else {
                    return this.settings.config?.prefs?.showDefaultAdditionalProductsCategory
                }
            }

            if (type === "stack_user_package_allowance") {
                if (
                    "showDefaultWebHostingCategory" in this.catalogue.config?.prefs
                ) {
                    return this.catalogue.config?.prefs?.showDefaultWebHostingCategory
                } else {
                    return true
                }
            }

            if (type === "cloud_server") {
                if (
                    !("showDefaultCloudServerCategory" in this.catalogue.config?.prefs)
                ) {
                    return true
                } else {
                    return this.catalogue.config?.prefs?.showDefaultCloudServerCategory
                }
            }

            if (type === "vps") {
                if (
                    !("showDefaultVpsCategory" in this.catalogue.config?.prefs)
                ) {
                    return true
                } else {
                    return this.catalogue.config?.prefs?.showDefaultVpsCategory
                }
            }

            if(type === "wordpress"){
                return this.catalogue.config?.prefs?.splitWordpressHosting
            }

            if (type.includes('custom_category')) {
                return this.customProductCategories.find(c => c.category == type).visible
            }

            return true
        },
        productCountCheck(type) {
            if (type.includes('custom_category')) return true
            switch(type) {
                case 'custom_limit_addon':
                case 'package':
                    if (this.productsAvailableByType['package'].constructor === Array) {
                        if (this.productsAvailableByType['package'].length === 0) {
                            return false
                        }
                    }

                    for(const p in this.productsAvailableByType[type]){
                        if( this.productsAvailableByType[type][p] ){
                            return true;
                        }
                    }
                    break;
                case 'stack_user_package_allowance':
                    if (this.productsAvailableByType['stack_user_package_allowance'].constructor === Array) {
                        if (this.productsAvailableByType['stack_user_package_allowance'].length === 0) {
                            return false
                        }
                    }
                    return true;
                    break;
                case 'wordpress':
                    if (
                        !this.productsAvailableByType['stack_user_package_allowance'] ||
                        !this.productsAvailableByType['stack_user_package_allowance']['wordpress']
                    ) {
                        return false
                    }
                default:
                    return true;
            }
            return false;
        },
        productAvailabiltyCheck(type) {
            if (
                this.productsAvailableByType &&
                this.productsAvailableByType[type] &&
                this.productCountCheck(type) &&
                this.isVisible(type)
            ) {
                return true
            }

            if (type === 'wordpress') {
                if (
                    this.productsAvailableByType?.stack_user_package_allowance?.wordpress &&
                    this.productCountCheck(type) &&
                    this.isVisible(type)
                ) {
                    return true
                }
            }

            return false
        }
    },
    components: {
    },
    async mounted() {
        await this.settings.preload(["config", "contact"])
        await Promise.all([
            $.ajax({
                method: "get",
                url: `/a/catalogue/_design/product/_view/byType`,
            }).then(
                (r, s, jqxhr) => {
                    if( r.managed_vps && !r.vps ){
                        r.vps = r.managed_vps;
                    }
                    var forSaleByType;
                    var planOnly = false;
                    if( r.package.length > 0 ){
                        forSaleByType = Object.keys(r.package);
                    } else {
                        forSaleByType = Object.keys(r.stack_user_package_allowance);
                        planOnly = true;
                    }
                    if( planOnly ){
                        this.productBoxes.stack_user_package_allowance.link.href = this.productBoxes.package.link.href;
                        this.productBoxes.stack_user_package_allowance.title = this.productBoxes.package.title;
                    }
                    if(
                        this.settings.config.prefs &&
                        this.settings.config.prefs.splitWordpressHosting &&
                        forSaleByType.length == 1 &&
                        forSaleByType[0] == 'wordpress'
                    ){
                        if( planOnly ){
                            this.productBoxes.stack_user_package_allowance.link.href = "/order-wordpress-hosting";
                        }
                        this.productBoxes.package.link.href = "/order-wordpress-hosting";
                    }
                    this.productsAvailableByType = r;
                }
            ).then(() => {
                $.ajax(`/a/catalogue/productCustomCategory`)
                .then(r => {
                    r.forEach((e, i) => {
                        e.category = `custom_category_${e.id}`
                        this.customProductCategories.push(e)
                        this.productsAvailableByType[`custom_category_${e.id}`] = true
                        this.productBoxes[`custom_category_${e.id}`] = {
                            link: {
                                href: `/pccategory?cat=${e.id}`,
                                label: e.linkText
                            },
                            title: e.label,
                            description: e.description,
                            icon: e.icon
                        }
                    })
                }).then(() => {
                    const category_order = this.settings.config.categoryOrder;

                    if (category_order) {
                        this.productBoxes = Object.keys(this.productBoxes).sort((a, b) => {
                            //  Check if string includes custom_category_ if so remove it when checking for index
                            const indexA = category_order.indexOf(a.replace('custom_category_', ''));
                            const indexB = category_order.indexOf(b.replace('custom_category_', ''));

                            if (indexA < indexB) return -1;
                            if (indexA > indexB) return 1;
                            return 0;
                        }).reduce(
                            (obj, key) => {
                              obj[key] = this.productBoxes[key];
                              return obj;
                            },
                            {}
                        );
                    }
                });
            })
        ])
    },
})
